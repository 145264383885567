// js
$(document).ready(() => {
  // Init all carousels
  const galleries = $(".gallery");

  galleries.each((index, value) => {
    const gallery = $(value);
    const viewWrapper = gallery.children(".gallery-view");
    const viewFrames = viewWrapper.find(".gallery-view-frame");
    const thumbnailsWrapper = gallery.children(".gallery-thumbnails");
    const thumbnailsImages = thumbnailsWrapper.find(
      ".gallery-thumbnails-image"
    );
    const activeClassName = "gallery-thumbnails-image_active";

    const handleCarusel = event => {
      const currentIndex = event ? event.item.index : 0;
      thumbnailsCarousel.trigger("to.owl.carousel", [currentIndex, 300, true]);
      thumbnailsImages.each((index, value) => {
        const image = $(value);
        currentIndex == image.attr("data-index")
          ? image.addClass(activeClassName)
          : image.removeClass(activeClassName);
      });
    };

    const thumbnailsCarousel = thumbnailsWrapper.owlCarousel({
      items: 9,
      margin: 10,
      loop: false,
      nav: false,
      dots: false,
      center: true,
      responsive: {
        0: {
          items: 3
        },
        768: {
          items: 5
        },
        1000: {
          items: 7
        }
      }
    });

    thumbnailsImages.click(event => {
      const target = $(event.currentTarget);
      const index = target.attr("data-index");
      thumbnailsImages.removeClass(activeClassName);
      !target.hasClass(activeClassName) && target.addClass(activeClassName);
      viewCarousel.trigger("to.owl.carousel", [index, 300, true]);
    });

    const viewCarousel = viewWrapper.owlCarousel({
      items: 1,
      stagePadding: 120,
      loop: false,
      nav: true,
      dots: false,
      autoplay: true,
      autoplayTimeout: 10000,
      responsiveClass: true,
      callbacks: true,
      onInitialized: handleCarusel,
      responsive: {
        0: {
          stagePadding: 20
        },
        768: {
          stagePadding: 50
        },
        1000: {
          stagePadding: 120
        }
      }
    });

    viewFrames.magnificPopup({
      type: "image"
    });

    viewCarousel.on("changed.owl.carousel", handleCarusel);
  });
});
