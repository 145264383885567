import dropkick from "dropkickjs";

// js
$(document).ready(function() {

  window.time2save.scrollNav = function() {
    var magic = document.querySelector("header .sub-bar");
    var navSub = document.querySelector("header .sub-bar nav");
    if (magic.offsetTop <= window.pageYOffset) {
      magic.style.height = navSub.offsetHeight + "px";
      navSub.classList.add("fluid");
    } else if (magic.offsetTop > window.pageYOffset) {
      navSub.classList.remove("fluid");
      magic.style.height = "";
    }
  };

  if ($("header .sub-bar nav").length) {
    window.addEventListener("scroll", window.time2save.scrollNav);
    window.addEventListener("load", window.time2save.scrollNav);
  }

  let backEvent = false;

  const toggleBurger = () => {
    $("header .burger-button").toggleClass("active");
    $("header .burger").toggleClass("active");
    $(".burger-container").slideToggle(100);
    if (backEvent)
      setTimeout(() => {
        $(".burger-container .sub-menu").show();
        $(".burger-container .main-menu").hide();
      }, 100);
  };

  $("header .burger-button").click(() => toggleBurger());
  $(".burger-container .bg").click(() => toggleBurger());

  $(".burger-container .sub-menu a.back").click(e => {
    e.preventDefault();
    $(".burger-container .sub-menu").hide();
    $(".burger-container .main-menu").show();
    backEvent = true;
  });

  $(".city-modal-wrapper .bg").click(e => {
    $(".city-modal-wrapper").fadeOut("fast");
  });
  $(".city-modal-wrapper .close").click(e => {
    $(".city-modal-wrapper").fadeOut("fast");
  });

  $("a.tooltip").tooltip({
    track: true,
    content() {
      return $(this)
        .attr("title")
        .replace(/\n/gi, "<br/>");
    }
  });

  $("select.select").select2({
    templateResult: function (data, container) {
      if (data.element) {
        $(container).addClass($(data.element).attr("class"));
      }
      return data.text;
    }
  });

  $(".faq .title").click(e => {
    $(e.target)
      .toggleClass("opened")
      .parent()
      .find(".answer")
      .toggle();
  });
  
  window.scrollToLeadForm = function() {
    $(".city-modal-wrapper").fadeOut("fast");
    $([document.documentElement, document.body]).animate({
      scrollTop: $('.form-consultation').offset().top-50
    }, 800);
  }

  if($('.article-10 .table-responsive table').length){
    $('.table-responsive table').clone().appendTo(".table-responsive").addClass('full').hide();
    $('.table-responsive table:first-child tr').detach(":not(:nth-child(-n+8))");
    $('.show-more-table').click(e=>{
      $('.table-responsive table').show();
      $('.table-responsive table:first-child').hide();
      $(e.target).hide();
    })
  }
});